<template>
  <sub-page
      :title="$t('documentflow.for_signature')"
      v-model="$store.state.settings.pageData"
      :no-content-condition="items.length === 0"
      no-content-text="documentflow.signature.no_content"
      no-content-icon="mdi-draw"
      icon="mdi-draw"
      :loading="loading"
      fill-height
  >
    <template #default>
      <ws-data-table
          class="mt-5"
          :items="items"
          :headers="headers"
          :row-action="($event) => openEditDocument($event)"
      >

        <template #item.date="{item}">
          <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date , false) }}</h5>
        </template>

        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
        </template>

        <template #item.is_signed="{item}">
          <div class="d-flex justify-center">
            <v-icon :color="item.is_signed ? wsACCENT : null">mdi-check-decagram</v-icon>
          </div>
        </template>

      </ws-data-table>
    </template>


    <template #dialog>
      <ws-dialog
          v-if="displayDialog"
          @save="signDocument"
          v-model="displayDialog"
          :title="!$t('documentflow.document.sign')"
          :save-text="$t('Sign')"
          width="800"
          :hide-save="entityData.is_signed"
      >

        <v-simple-table :style="`border : 1px solid ${wsBACKGROUND}`" >
          <tbody>
          <tr>
            <td width="100px" :style="`border-color : ${wsBACKGROUND}`"><h5 :style="`color : ${wsACCENT}`">{{ $t('Document') }}:</h5> </td>
            <td :style="`border-color : ${wsBACKGROUND}`"><h5> {{ entityData.name }}</h5></td>
          </tr>
          <tr>
            <td :style="`border-color : ${wsBACKGROUND}`"><h5 :style="`color : ${wsACCENT}`">{{ $t('Type') }}:</h5> </td>
            <td :style="`border-color : ${wsBACKGROUND}`"><h5> {{ entityData.type }}</h5></td>
          </tr>
          <tr>
            <td :style="`border-color : ${wsBACKGROUND}`"><h5 :style="`color : ${wsACCENT}`">{{ $t('Code') }}:</h5> </td>
            <td :style="`border-color : ${wsBACKGROUND}`"><h5> {{ entityData.code }}</h5></td>
          </tr>
          <tr>
            <td :style="`border-color : ${wsBACKGROUND}`"><h5 :style="`color : ${wsACCENT}`">{{ $t('Date') }}:</h5> </td>
            <td :style="`border-color : ${wsBACKGROUND}`"><h5> {{ MONTH_DAY_TIME(entityData.date , false) }}</h5></td>
          </tr>
          </tbody>
        </v-simple-table>

        <v-sheet class="d-flex justify-space-between align-center mt-5 pa-3 wsRoundedLight" :style="`border : 1px solid ${wsBACKGROUND}`" >
          <div class="d-flex align-center">
            <v-icon class="mr-2" :color="wsACCENT">mdi-file</v-icon>
            <h5 :style="`color : ${wsACCENT}`" class="shorten-text"> {{ entityData.file_name }}</h5>
          </div>
          <v-btn :color="wsACCENT" @click="downloadFile(entityData.file_data)" icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-sheet>


      </ws-dialog>
    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Tasks",
  data() {
    return {
      items : [],
      loading : false,
      entityData : {},
      displayDialog : false
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Date') , value : 'date' , width : 10 },
        { text : this.$t('Type') , value : 'type_name' , width : 10 },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Code') , value : 'code' , width : 10 },
        { text : this.$t('IsSigned') , value : 'is_signed' , width : 10 }
      ]
    },
  },
  methods : {
    ...mapActions('documentflow', [
      'GET_SIGNATURE_REQUESTS',
      'SIGN_DOCUMENT'
    ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    async downloadFile(item) {

      let result = await this.GET_PRIVATE_FILE(item.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    openEditDocument(item) {

      this.entityData = this.COPY(item)
      this.displayDialog = true
    },
    async signDocument(value) {

      this.entityData.sign = value

      let result = await this.SIGN_DOCUMENT(this.entityData)
      if ( !result )  {
        this.ERROR()
      }

      let index = this.items.findIndex(el => el.uuid === result.uuid)
      this.items[index] = this.COPY(result)
      this.items = this.COPY(this.items)

      this.displayDialog = false

    },

    async initPage() {
      this.loading = true
      let result = await this.GET_SIGNATURE_REQUESTS()
      if ( !result )  {
        this.loading = false
        return this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
      this.loading = false
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>